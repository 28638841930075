import { useState, useEffect } from 'react'
import _debounce from 'lodash/debounce'

export const useWindowSize = ({ listenForResize = true } = {}) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    const handleRresizeDebounced = _debounce(handleResize, 250)
    listenForResize && window.addEventListener('resize', handleRresizeDebounced)
    handleResize()
    return () => {
      listenForResize && window.removeEventListener('resize', handleRresizeDebounced)
    }
  }, [listenForResize])
  return windowSize
}
