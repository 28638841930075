import { css } from '@emotion/react'
import breakpoints from '../../theme/breakpoints'

export const plugButtonsHoverStyles = css`
  & button:not(.StartFromButton) {
    opacity: 1;
  }
  @media all and (min-width: ${breakpoints.lg}) {
    & button:not(.StartFromButton) {
      opacity: 0;
    }
    & button:not(.StartFromButton):focus-visible {
      opacity: 1;
    }
    &:hover button:not(.StartFromButton) {
      opacity: 1;
    }
  }
`
