import React from 'react'
import PropTypes from 'prop-types'
import CustomButton from '../CustomButton'
import Icon from '../Icon'
import Box from '../Box'
import { useColors } from '../theme/Color'

const calculateCircle = buttonSize => {
  const radius = buttonSize / 2 - 1
  const circumference = 2 * Math.PI * radius
  const centerxy = buttonSize / 2
  return {
    radius,
    circumference,
    cx: centerxy,
    cy: centerxy
  }
}

const progressToStrokeOffset = (percentage, circumference) => {
  // if (!(currentTime >= 0) || !(duration > 0) || !(circumference > 0)) {
  //   return circumference
  // }
  return circumference - (percentage / 100) * circumference
}

const PlayProgressButton = ({ percentage = 0, fixed = false, isPlaying, onClick, ...restProps }) => {
  const { radius, circumference, cx, cy } = calculateCircle(60)

  return (
    <CustomButton
      onClick={onClick}
      p="0 !important"
      w={'60px'}
      h={'60px'}
      rounded="full"
      pos="relative"
      data-test="playPauseEpisode"
      variant="playpause"
      {...restProps}
    >
      <Icon iconId={isPlaying ? 'nrk-media-pause' : 'nrk-media-play'} />
      <Box as="svg" position="absolute" d={fixed ? 'contents' : 'block'} w={'100%'} h={'100%'} top="0">
        {percentage > 0 && <Box as="circle" strokeWidth="2" fill="transparent" r={radius} cx={cx} cy={cy} />}
        <Box
          as="circle"
          stroke={useColors().main.contrastLight}
          strokeWidth="2"
          fill="transparent"
          r={radius}
          cx={cx}
          cy={cy}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={progressToStrokeOffset(percentage, circumference)}
          transform="rotate(-90deg)"
          transformOrigin="50% 50%"
          transition="stroke-dashoffset 0.35s"
        />
      </Box>
    </CustomButton>
  )
}

PlayProgressButton.propTypes = {
  percentage: PropTypes.number,
  isPlaying: PropTypes.bool,
  fixed: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default PlayProgressButton
