import { css } from '@emotion/react'

export const initHoverColorStyles = (colors, targetSelector = '.PlugTagline') => {
  return css`
    &:hover,
    &:focus-within {
      ${targetSelector} {
        color: ${colors.light};
      }
    }
  `
}

const hoverColorStyles = props => initHoverColorStyles(props)

export default hoverColorStyles
