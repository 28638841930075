import { css } from '@emotion/react'

export const focusVisibleStyles = css`
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
`

export const focusWithinStyles = css`
  & a:focus {
    outline: none;
  }
`
