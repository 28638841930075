import React, { memo, useCallback, useEffect, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import { trackClick, trackImpressions, trackSnowplowEvent, spCategory } from '../../clientMonitoring'
import { EPISODE, PODCAST_EPISODE, STANDALONE_PROGRAM, CHANNEL } from '../../pages/FrontPage/helpers/plugTypes'
import { CROSS_DOMAIN_RECOMMENDATIONS, SQUARED, SQUARED_LOGO } from '../../pages/FrontPage/helpers/displayContracts'
import { useWindowSize } from '../hooks/useWindowSize'
import { usePlayerElementState } from '../StickyPlayer/context/PlayerElementStateContext'
import { usePlayerMetadata } from '../StickyPlayer/context/PlayerMetadataContext'

const noop = () => {}

export const withImpressionAndClickTracking = WrappedComponent => {
  const ImpressionAndClickTracking = memo(({ onClick = noop, onPlayClick = noop, ...restProps }) => {
    const { width } = useWindowSize({ listenForResize: false })
    const rootMarginBottom = width >= 768 ? '-96px' : '-80px'
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.6,
      rootMargin: `0px 0px ${rootMarginBottom} 0px`
    })
    const {
      type,
      sectionId,
      sectionTitle,
      indexOfSection,
      contentTitle,
      displayContract,
      indexInSection,
      imageId,
      recommendationId,
      contentId,
      contentSource,
      contentKind,
      pageId
    } = restProps
    const { episodeId: episodeIdFromMetadata } = usePlayerMetadata()
    const { isPlaying: playerIsPlaying } = usePlayerElementState()
    const snowplowCategory = restProps.pageId === 'discover' ? spCategory.Frontpage : spCategory.CategoryPage

    const wrappedOnClick = useCallback(() => {
      onClick(restProps)
      trackClick({
        snowplowPlugEntity: toSnowplowPlugEntity(restProps),
        snowplowContentEntity: { id: restProps.pageId }
      })
      trackSnowplowEvent(snowplowCategory, 'PlugTapped')
    }, [onClick, restProps, snowplowCategory])

    const wrappedOnPlayClick = useCallback(() => {
      onPlayClick(restProps)
      onPLayClickHandler(restProps, playerIsPlaying, episodeIdFromMetadata, snowplowCategory)
    }, [onPlayClick, restProps, snowplowCategory, playerIsPlaying, episodeIdFromMetadata])

    const maybeOnPlayClickHandler = useMemo(() => {
      if (
        ![EPISODE, PODCAST_EPISODE, STANDALONE_PROGRAM, CHANNEL].includes(restProps.type) ||
        [SQUARED_LOGO, SQUARED, CROSS_DOMAIN_RECOMMENDATIONS].includes(restProps.displayContract)
      )
        return {}

      return { onPlayClick: wrappedOnPlayClick }
    }, [restProps, wrappedOnPlayClick])

    useEffect(() => {
      if (inView) {
        trackImpressions({
          snowplowPlugEntities: [
            toSnowplowPlugEntity({
              sectionId,
              sectionTitle,
              indexOfSection,
              displayContract,
              indexInSection,
              imageId,
              contentTitle,
              recommendationId,
              contentId,
              contentSource,
              contentKind,
              type
            })
          ],
          snowplowContentEntity: { id: pageId }
        })
      }
    }, [
      contentId,
      contentKind,
      contentSource,
      displayContract,
      imageId,
      inView,
      indexInSection,
      indexOfSection,
      pageId,
      recommendationId,
      sectionId,
      sectionTitle,
      contentTitle,
      type
    ])

    return <WrappedComponent {...restProps} {...maybeOnPlayClickHandler} onClick={wrappedOnClick} ref={ref} />
  })

  ImpressionAndClickTracking.propTypes = {
    onClick: PropTypes.func,
    onPlayClick: PropTypes.func,
    sectionId: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string,
    indexOfSection: PropTypes.number,
    contentTitle: PropTypes.string,
    displayContract: PropTypes.string,
    indexInSection: PropTypes.number,
    imageId: PropTypes.string,
    recommendationId: PropTypes.string,
    contentId: PropTypes.string.isRequired,
    contentSource: PropTypes.string,
    contentKind: PropTypes.string,
    pageId: PropTypes.string.isRequired,
    type: PropTypes.string
  }

  ImpressionAndClickTracking.displayName = `WithImpressionAndClickTracking(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'WrappedComponent'})`

  return ImpressionAndClickTracking
}

const onPLayClickHandler = (restProps, playerIsPlaying, episodeIdFromMetadata, snowplowCategory) => {
  trackClick({
    snowplowPlugEntity: toSnowplowPlugEntity(restProps),
    snowplowContentEntity: { id: restProps.pageId }
  })

  const label = (() => {
    if (restProps.type === CHANNEL) return 'fromLive'
    if (restProps.startTime) return 'fromTimestamp'
    return 'fromStart'
    // missing fromProgress
  })()

  if (playerIsPlaying) {
    if (episodeIdFromMetadata !== restProps.contentId) {
      trackSnowplowEvent(snowplowCategory, 'PlayButtonTapped', label)
    } else {
      trackSnowplowEvent(snowplowCategory, 'PauseButtonTapped')
    }
  } else {
    trackSnowplowEvent(snowplowCategory, 'PlayButtonTapped', label)
  }
}

export const withOnPlayClickTracking = WrappedComponent => {
  const onPlayClickTracking = memo(({ onClick = noop, onPlayClick = noop, ...restProps }) => {
    const { episodeId: episodeIdFromMetadata } = usePlayerMetadata()
    const { isPlaying: playerIsPlaying } = usePlayerElementState()
    const snowplowCategory = restProps.pageId === 'discover' ? spCategory.Frontpage : spCategory.CategoryPage

    const wrappedOnPlayClick = useCallback(() => {
      onPlayClick(restProps)
      onPLayClickHandler(restProps, playerIsPlaying, episodeIdFromMetadata, snowplowCategory)
    }, [onPlayClick, restProps, snowplowCategory, playerIsPlaying, episodeIdFromMetadata])

    return <WrappedComponent {...restProps} onPlayClick={wrappedOnPlayClick} />
  })

  onPlayClickTracking.propTypes = {
    onClick: PropTypes.func,
    onPlayClick: PropTypes.func,
    pageId: PropTypes.string.isRequired,
    contentId: PropTypes.string.isRequired
  }

  onPlayClickTracking.displayName = `withOnPlayClickTracking(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'WrappedComponent'})`

  return onPlayClickTracking
}

export const withClickTracking = WrappedComponent => {
  const ClickTracking = memo(({ onClick = noop, onPlayClick = noop, ...restProps }) => {
    const wrappedOnClick = useCallback(() => {
      onClick(restProps)
      trackClick({
        snowplowPlugEntity: toSnowplowPlugEntity(restProps),
        snowplowContentEntity: { id: restProps.pageId }
      })
      trackSnowplowEvent(restProps.pageId === 'discover' ? spCategory.Frontpage : spCategory.CategoryPage, 'PlugTapped')
    }, [onClick, restProps])

    return <WrappedComponent {...restProps} onClick={wrappedOnClick} />
  })

  ClickTracking.propTypes = {
    onClick: PropTypes.func,
    onPlayClick: PropTypes.func,
    sectionId: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string,
    indexOfSection: PropTypes.number,
    contentTitle: PropTypes.string,
    displayContract: PropTypes.string,
    indexInSection: PropTypes.number,
    imageId: PropTypes.string,
    recommendationId: PropTypes.string,
    contentId: PropTypes.string.isRequired,
    contentSource: PropTypes.string,
    contentKind: PropTypes.string,
    pageId: PropTypes.string.isRequired,
    type: PropTypes.string
  }

  ClickTracking.displayName = `WithImpressionAndClickTracking(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'WrappedComponent'})`

  return ClickTracking
}

function toSnowplowPlugEntity({
  sectionId,
  sectionTitle,
  indexOfSection,
  displayContract,
  indexInSection,
  imageId,
  recommendationId,
  contentTitle,
  contentId,
  contentSource,
  contentKind,
  type
}) {
  return {
    section: { id: sectionId, name: sectionTitle, index: indexOfSection },
    title: contentTitle,
    styling: displayContract,
    sectionIndex: indexInSection,
    imageId,
    recommendationId,
    content: {
      id: contentId,
      kind: contentKind || type,
      source: contentSource
    }
  }
}
