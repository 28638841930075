import { css } from '@emotion/react'
import breakpoints from '../../theme/breakpoints'

const transition = 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)'
const transform = 'translate3d(0, -4px, 0)'

const createTemplateString = targetSelector => {
  return `
    ${targetSelector} {
      transition: ${transition};
    }
    &:hover,
    &:focus-within,
    &:active {
    ${targetSelector} {
        transform: ${transform};
      }
    }
  `
}

export const initHoverMovementStyles = (targetSelector = '& > *') => {
  return css`
    ${createTemplateString(targetSelector)}
  `
}
const hoverStyles = initHoverMovementStyles()

export default hoverStyles

export const initHoverMovementStylesFromMd = (targetSelector = '& > *') => {
  return css`
    @media screen and (min-width: ${breakpoints.md}) {
      ${createTemplateString(targetSelector)}
    }
  `
}

export const hoverMovementStylesFromMd = initHoverMovementStylesFromMd()

export const initHoverMovementStylesFromLg = (targetSelector = '& > *') => {
  return css`
    @media screen and (min-width: ${breakpoints.lg}) {
      ${createTemplateString(targetSelector)}
    }
  `
}

export const hoverMovementStylesFromLg = initHoverMovementStylesFromLg()
