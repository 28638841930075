export const PODCAST = 'podcast'
export const PODCAST_EPISODE = 'podcastEpisode'
export const SERIES = 'series'
export const EPISODE = 'episode'
export const CHANNEL = 'channel'
export const STANDALONE_PROGRAM = 'standaloneProgram'
export const PODCAST_SEASON = 'podcastSeason'
export const PROGRAM = 'program'
export const LINK = 'link'
export const PAGE = 'page'
