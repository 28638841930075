import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../IconButton'
import EpisodeMenu from '../../../components/EpisodeMenu/EpisodeMenu'
import { focusVisibleStyles } from '../../Plugs/styles/focusStyles'
import { css } from '@emotion/react'

const noop = () => {}

const PlugEpisodeMenu = ({
  episodeId,
  seriesId,
  episodeTitle,
  seriesTitle,
  channelTitle,
  type,
  linkUrl,
  duration,
  onShareContent = noop,
  onViewQueue = noop,
  onRemoveFromQueue = noop,
  onUnFavourite = noop,
  onFavourite = noop,
  onUnMarkCompleted = noop,
  onMarkCompleted = noop,
  onClickDuplicate = noop,
  onChangeQueue = noop,
  onClickTitle = noop,
  onDialogOpen = noop,
  ...restProps
}) => {
  const [isHiddenState, setIsHiddenState] = useState(true)
  const setIsHidden = hidden => {
    onDialogOpen()
    setIsHiddenState(hidden)
  }

  return (
    <>
      <IconButton
        aria-label="Flere valg"
        className="open-more-choices"
        rounded="full"
        iconId="nrk-more"
        w="32px"
        h="32px"
        padding="0 !important"
        display="flex"
        justifyContent="center"
        css={[
          focusVisibleStyles,
          css`
            svg {
              filter: drop-shadow(1px 1px 1.5px rgb(0 0 0 / 0.5));
            }
          `
        ]}
        {...restProps}
        onClick={e => {
          e.preventDefault()
          setIsHidden(!isHiddenState)
          e.currentTarget.focus()
        }}
      />
      <EpisodeMenu
        isHiddenState={isHiddenState}
        setIsHiddenState={setIsHiddenState}
        episodeId={episodeId}
        seriesId={seriesId}
        episodeTitle={episodeTitle}
        seriesTitle={seriesTitle}
        channelTitle={channelTitle}
        targetType={type}
        linkUrl={linkUrl}
        duration={duration}
        onChangeQueue={onChangeQueue}
        onShareContent={onShareContent}
        onViewQueue={onViewQueue}
        onRemoveFromQueue={onRemoveFromQueue}
        onUnFavourite={onUnFavourite}
        onFavourite={onFavourite}
        onUnMarkCompleted={onUnMarkCompleted}
        onMarkCompleted={onMarkCompleted}
        onClickDuplicate={onClickDuplicate}
        onClickTitle={onClickTitle}
      />
    </>
  )
}

PlugEpisodeMenu.propTypes = {
  episodeId: PropTypes.string,
  seriesId: PropTypes.string,
  episodeTitle: PropTypes.string,
  seriesTitle: PropTypes.string,
  channelTitle: PropTypes.string,
  type: PropTypes.string,
  duration: PropTypes.string,
  linkUrl: PropTypes.string,
  onShareContent: PropTypes.func,
  onViewQueue: PropTypes.func,
  onRemoveFromQueue: PropTypes.func,
  onUnFavourite: PropTypes.func,
  onFavourite: PropTypes.func,
  onUnMarkCompleted: PropTypes.func,
  onMarkCompleted: PropTypes.func,
  onClickDuplicate: PropTypes.func,
  onChangeQueue: PropTypes.func,
  onClickTitle: PropTypes.func,
  onDialogOpen: PropTypes.func
}

export default PlugEpisodeMenu
